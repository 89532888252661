import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/questy-cebit.png";

export const query = graphql`
  {
    zdjecie1: file(
      relativePath: {
        eq: "analiza-potrzeb-klienta-zakres-oczekiwan-e1519822345780.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 785, layout: CONSTRAINED)
      }
    }
    zdjecie2: file(
      relativePath: { eq: "Szczegoly-oferty-handlowej-1440x1080.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 785, layout: CONSTRAINED)
      }
    }
  }
`;
const Cebit = ({ data }) => {
  return (
    <Artykul
      title="Questy na CEBIT 2014 w Hannowerze"
      keywords={["questy"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Questy na CEBIT 2014 w Hannowerze"
      metaTitle="Questy na CEBIT 2014 w Hannowerze"
      metaDescription="Questy ✅ Udział firmy Questy na CEBIT 2014 w Hannowerze • Producent oprogramowania dla firm • Aura Business na CEBIT 2014"
    >
      <br />
      <p>
        Firma Questy uczestniczyła w prestiżowych tagach IT – CeBIT 2014. Targi
        odbyły się w dniach 10 ‑ 14 marca 2014 roku, a system Aura Business –{" "}
        <strong> flagowy produkt firmy Questy</strong> – cieszył się sporym
        zainteresowaniem odwiedzających.
      </p>
      <br />
      <p>
        Targi CeBIT to olbrzymia impreza o światowym zasięgu – w tym roku
        odwiedziło ją ponad 210 tys. osób z wszystkich kontynentów. Firma Questy
        w tym roku korzystała ze stoiska regionu Wielkopolskiego,
        zorganizowanego przez Urząd Marszałkowski Województwa Wielkopolskiego.
      </p>
      <br />
      <p>
        Udział w tak prestiżowym wydarzeniu zapewnił nam dawkę aktualnej wiedzy
        w zakresie nowinek technologicznych, ale przede wszystkim umożliwił
        promocję naszego głównego produktu – systemu
        <strong>
          {" "}
          <a href="https://aurabusiness.pl/">Aura Business</a>{" "}
        </strong>{" "}
        – na arenie międzynarodowej. Tym bardziej cieszy nas ciepłe przyjęcie
        naszych propozycji wśród zwiedzających – zarówno tych z Polski, jak i
        całego świata.
      </p>
       <br />
      <br />
      <center>
        <iframe
          width="748"
          height="453"
          src="https://www.youtube.com/embed/i3Ryv6vVVQI"
          frameborder="0"
          allow="accelerometer; 
      autoplay; encrypted-media; gyroscope; 
      picture-in-picture"
          allowfullscreen
        ></iframe>
      </center>
    </Artykul>
  );
};

export default Cebit;
